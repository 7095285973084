@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

label {
  @apply text-[17px] font-[500];
}

::-webkit-scrollbar {
  display: none;
}

.overlay-custom-class-name h1{
  @apply text-base font-bold text-center;
}

.overlay-custom-class-name .react-confirm-alert-body{
  @apply flex flex-col;
}

.overlay-custom-class-name .react-confirm-alert-button-group{
  @apply flex mx-auto;
}

.overlay-custom-class-name .react-confirm-alert-button-group button{
  @apply h-8 w-20;
}