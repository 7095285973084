@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 30px;
  height: 30px;
  border: 5px solid #e5e2e2;
  border-top: 5px solid #2c95fb;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}
